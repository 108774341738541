<template>
  <span>{{ status[num] }}</span>
</template>

<script>
export default {
  name: "ReportStatus",
  props: {
    num: {},
  },
  data() {
    return {
      status: [
        "已发放",
        "已接收",
        "退回中间报告",
        "提交中间报告",
        "审核中间报告",
        "批准中间报告",
        "退回",
        "提交",
        "审核",
        "批准",
        "完结",
      ],
    };
  },
};
</script>

<style scoped>
.red {
  padding: 10px;
  border-radius: 5px;
  background-color: green;
  color: white;
}
</style>
