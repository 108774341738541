<template>
  <span :class="colorStyle">{{ mydate }}</span>
</template>

<script>
export default {
  name: "DateLabel",
  props: {
    mydate: {},
  },
  computed: {
    colorStyle() {
      const day = (new Date(this.mydate) - Date.now()) / (24 * 60 * 60 * 1000);
      switch (true) {
        case day <= 0:
          return "gray";
        case day < 7:
          return "red0";
        case day < 14:
          return "yellow";
        case day < 21:
          return "green";
        default:
          return "normal";
      }
    },
  },
};
</script>

<style scoped>
.gray {
  padding: 10px;
  background-color: grey;
  color: white;
  border-radius: 5px;
}
.red0 {
  padding: 10px;
  background-color: rgb(206, 43, 43);
  color: white;
  border-radius: 5px;
}
.yellow {
  padding: 10px;
  background-color: yellow;
  color: black;
  border-radius: 5px;
}
.green {
  padding: 10px;
  background-color: green;
  color: white;
  border-radius: 5px;
}
.normal {
  padding: 10px;
  background-color: white;
  color: black;
  border-radius: 5px;
}
</style>
